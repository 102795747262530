:root {
    --box-color: rgb(228, 228, 228);
}

.tutorial-card {
    position: fixed;
    width: 70vw;
    max-width: 360px;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.95);
    color: black;
    font-size: medium;
    font-family: sans-serif;
    z-index: 10;
}

/* @media (prefers-color-scheme: dark) {
    .tutorial-card  {
        background-color: rgba(var(--ion-color-primary-contrast-rgb), 0.2);
    }
} */

.tutorial-card-hide {
    display: none;
}

.tutorial-card-button {
    float: inline-end;
    padding: 6px;
    font-size: 14px;
    background-color: none;
}
.tutorial-card-button-bottom {
    position: relative;
    width: fit-content;
    font-size: 14px;
    background-color: none;
}

.tutorial-card-body {
    padding: 0px 10px;
    padding-top: 0px;
}

.dialogue-box {
    position: relative;
    background-color: var(--box-color);
    width: 80%;
    padding: 8px 8px;
    margin-top: 10px;
    border-radius: 8px;
}
.dialogue-box p {
    font-size: 12px;
}
.db-right:after {
    position: absolute;
    content: '';
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid var(--box-color);
    bottom: -20px;
    right: 40px;
    z-index: -1;
}
.db-left:after {
    position: absolute;
    content: '';
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid var(--box-color);
    bottom: -20px;
    right: 80px;
    z-index: -1;
}

.dialogue-box p {
    margin: 0;
}

.tutorial-card-body img {
    max-width: 60%;
}
.left {
    margin-right: 40%;
}
.right {
    margin-left: 40%;
}

.img-arrow {
    display: grid;
    grid-template-columns: 1fr 0fr;
}

.float-bottom {
    position: absolute;
    right: -6px;
    bottom: 4px;
}