.loading-absolute {
    position: absolute;
    width: 100%;
}

.my-custom-backdrop {
    inset: 0px;
    display: block;
    position: absolute;
    transform: translateZ(0px);
    contain: strict;
    cursor: pointer;
    opacity: 0.01;
    touch-action: none;
    z-index: 2;

}

.ion-content-scroll-host{
    transition: all 1000ms ease-in;
}

.adjusted-content{
    height:3em;
}
.filter-aligned{
    justify-content: end;
}

.filter-list {
    height: 3em;
    margin: 0;
    margin-top: 0;
    padding-top: 0;
    padding: 0;
}

.filter-size{
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
}

.filter-rounded_border{
    border-radius: .25rem;
}
.filter-item-size {
    min-height: auto;
}
.option-filter {
    font-size: medium;
}
/* ion-select {
    background-color: transparent;

}
ion-select::part(text) {
  display: none !important;
}
ion-select::part(icon) {
    content: url("../../public/assets/icon/filter-circle-outline.svg");
    opacity: 1;
}

.select-icon {
    width: 32px !important;
    height: 32px !important;
} */